import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import useParallax from './useParallax.ts';
import { FaStar } from 'react-icons/fa';

const LandingPage = () => {
  const parallax = useParallax();
  const secondParallax = useParallax();

  const getLineContent = (progress: number) => {
    if (progress <= 0.25) return '';
    if (progress <= 0.50) return '- creators pushing sensations over information';
    if (progress <= 0.75) return '- the most outrageous manipulators getting on top';
    return (
      <>
        - same scams played repeatedly in public with multi-million audiences watching
        <RedExclamation>!</RedExclamation>
      </>
    );
  };

  const getSecondParallaxContent = (progress: number) => {
    if (progress <= 0.50) return '';
    return (
      <>
        and issue trust ratings for public personas
        <StarsContainer>
          {[...Array(5)].map((_, index) => (
            <FaStar key={index} size={58} color="#D9D9D9" />
          ))}
        </StarsContainer>
      </>
    );
  };

  const getStarFill = (index: number, progress: number) => {
    // Calculate how many stars should be filled based on progress
    const starsToFill = Math.floor(progress * 5);
    // For partial fill of the current star
    const partialFill = (progress * 5) % 1;
    
    if (index < starsToFill) {
      return '#D9D9D9'; // Fully filled
    } else if (index === starsToFill) {
      return `rgba(217, 217, 217, ${partialFill})`; // Partially filled
    }
    return 'transparent'; // Empty star
  };

  return (
    <PageContainer>
      {/* Hero Section */}
      <Section>
        <ContentWrapper>
          <MainHeading>
            Fraud and manipulation are<br />
            taking over the internet
          </MainHeading>
        </ContentWrapper>
      </Section>

      {/* Parallax Section with Context */}
      <ParallaxSection ref={parallax.ref}>
        <ParallaxViewport isVisible={parallax.isVisible}>
          <ParallaxContent>
            <SubHeading>
              Lack of accountability<br />
              encourages unethical behavior
            </SubHeading>
            <LeadingText>Leading to:</LeadingText>
            <BarrelContainer>
              <BarrelText>{getLineContent(parallax.scrollProgress)}</BarrelText>
            </BarrelContainer>
          </ParallaxContent>
        </ParallaxViewport>
      </ParallaxSection>

      {/* Solution Section */}
      <ParallaxSection ref={secondParallax.ref}>
        <ParallaxViewport isVisible={secondParallax.isVisible}>
          <ParallaxContent>
            <Description>
              We detect, analyze and remember fraud and<br />
              manipulation
            </Description>
            <SecondaryDescription>
              and issue trust ratings for public personas
            </SecondaryDescription>
            <StarsContainer>
              {[...Array(5)].map((_, index) => (
                <StarWrapper key={index}>
                  <FaStar 
                    size={58} 
                    color="#D9D9D9" 
                    style={{ opacity: 0.2 }} // Background star
                  />
                  <FaStar 
                    size={58} 
                    color={getStarFill(index, secondParallax.scrollProgress)}
                    style={{ 
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  />
                </StarWrapper>
              ))}
            </StarsContainer>
          </ParallaxContent>
        </ParallaxViewport>
      </ParallaxSection>

      {/* Final Section */}
      <Section>
        <ContentWrapper>
          <FinalHeading>
            We'll keep every liar in check
          </FinalHeading>
          <InterestedText>
            Interested?
          </InterestedText>
          <EmailText>
            Email us at <a href="mailto:friends@neurolock.ai">friends@neurolock.ai</a>
          </EmailText>
        </ContentWrapper>
      </Section>
    </PageContainer>
  );
};

// Styled Components
const PageContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
`;

const Section = styled.section`
  min-height: 729px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 16px 0;
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
  padding: 0 16px;
  border-radius: 4px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MainHeading = styled.h1`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 60px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  max-width: 746px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 30px; // Mobile-specific font size
  }
`;

const SubHeading = styled.h2`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #000000;
  max-width: 653px;
  margin: 0 auto 80px;

  @media (max-width: 768px) {
    font-size: 25px; // Mobile-specific font size
    line-height: 30px; // Mobile-specific line height
  }
`;

const LeadingText = styled.p`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 20px; // Mobile-specific font size
    line-height: 24px; // Mobile-specific line height
  }
`;

const ParallaxSection = styled.section`
  height: 300vh;
  position: relative;
`;

const ParallaxViewport = styled.div<{ isVisible: boolean }>`
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 1280px;
  height: 729px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
`;

const ParallaxContent = styled.div`
  text-align: center;
  width: 100%;
`;

const BarrelContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 0;
  border-radius: 4px;
  padding: 0;
  min-height: 114px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

const BarrelText = styled.div`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #000000;
  padding: 0 20px;
  max-width: 1147px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 20px; // Mobile-specific font size
    line-height: 24px; // Mobile-specific line height
  }
`;

const Description = styled.div`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #000000;
  max-width: 1008px;
  margin: 0 auto;
  margin-bottom: 64px;

  @media (max-width: 768px) {
    font-size: 25px; // Mobile-specific font size
    line-height: 30px; // Mobile-specific line height
  }
`;

const SecondaryDescription = styled.div`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 50px;
  line-height: 75px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 64px;

  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 32px;
  }
`;

const StarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const StarsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 0;
`;

const FinalHeading = styled.h2`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #000000;
  max-width: 609px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 25px; // Mobile-specific font size
    line-height: 30px; // Mobile-specific line height
  }
`;

const InterestedText = styled.div`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-top: 64px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    margin-top: 32px;
  }
`;

const EmailText = styled.div`
  font-family: 'Sofia Sans';
  font-weight: 200;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const RedExclamation = styled.span`
  color: #FF0000;
`;

export default LandingPage; 
